<template>
  <div>
    <div class="ajjjj" id="wrap">
      <!-- logo -->
      <div class="logo fbox fbox-acenter">
        <div class="imgBox">
          <img :src="$store.state.projectInfoServe?.platformLogoPath" />
        </div>
        {{ $store.state.projectInfoServe?.platformName }}
      </div>
      <!-- nav -->
      <div class="nav fbox fbox-acenter">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :name="item.name"
            v-for="(item, index) in navList"
            :key="index"
          >
            <div slot="label">
              <div class="navItem color3 f16">
                {{ item.title }}
                <div class="children" v-if="item.children">
                  <div
                    v-for="iitem in item.children"
                    @click="currentChangeTable(iitem)"
                  >
                    {{ iitem.title }}
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="login-bt">
          <span
            @click="loginGo(item)"
            class="ani"
            v-for="(item, index) in loginGoList"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
      <!-- phone -->
      <div class="phone">
        <div class="color3 f16">
          <i class="el-icon-phone color-blue"></i>
          13422347150,15511086126,18931018991
        </div>
        <div class="color3 f16">
          <i class="el-icon-time color-blue"></i>
          (工作时间) 8:00-20:00
        </div>
      </div>
    </div>
    <!-- 站位 -->
    <div style="width: 100%; height: 80px"></div>
  </div>
</template>
<script>
export default {
  props: ["tap"],
  data() {
    return {
      activeName: "",
      bgColor: "rgba(0,0,0,0.5);",
      loginGoList: ["用户登录", "评标系统", "专家登录"],
      navList: [
        {
          name: "home",
          title: "首页",
        },
        {
          name: "zbinfo",
          title: "招标信息",
        },
        {
          name: "news",
          title: "新闻公告",
        },
        {
          name: "four",
          title: "保函办理",
        },
        {
          name: "five",
          title: "CA办理",
        },
        {
          name: "Tool",
          title: "常用工具",
        },
        {
          name: "assetTransaction",
          title: "资产交易",
          children: [
            {
              name: "ruralAssetsTransaction",
              title: "农村资产交易",
            },
            {
              name: "stateOwnedAssetsTransaction",
              title: "国有资产交易",
            },
            {
              name: "auction",
              title: "拍卖",
            },
          ],
        },
      ],
      // bgColor2:require('@/assets/images/980.jpg')
    };
  },

  mounted() {
    this.routerChange();
  },
  methods: {
    //跳转其他url
    loginGo(title) {
      if (title == "用户登录") {
        location.href = this.$store.state.userUrl;
      } else if (title == "专家登录") {
        location.href = this.$store.state.expertLoginUrl;
      } else if (title == "定标系统") {
        location.href = this.$store.state.picketageExpertUrl;
      } else if (title == "用户注册") {
        location.href = this.$store.state.oldUrl;
      } else if (title == "评标系统") {
        location.href = this.$store.state.expertUrl;
      }
    },
    //二级跳转
    currentChangeTable(item) {
      this.$router.push("/" + item.name);
    },
    // 判断路由
    routerChange() {
      console.log("666666");
      this.activeName = this.$route.name;
      if (
        this.$route.name == "ruralAssetsTransaction" ||
        this.$route.name == "stateOwnedAssetsTransaction" ||
        this.$route.name == "auction"
      ) {
        this.activeName = "assetTransaction";
      }
    },
  },
  watch: {
    "$route.name"() {
      this.routerChange();
    },
    activeName(newVal) {
      switch (newVal) {
        case "home":
          this.$router.push("/home");
          break;
        case "news":
          this.$router.push("/news");
          break;
        case "zbinfo":
          this.$router.push("/zbinfo");
          break;
        case "five":
          window.open(this.$store.state.ggzUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
        case "Tool":
          this.$router.push("/Tool");
          break;
        case "four":
          window.open(this.$store.state.websiteUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
      }
    },
    tap(newVal) {
      this.activeName = newVal;
    },
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 500px) {
  .ajjjj {
    display: none;
  }
}
.ajjjj {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  color: white;
  z-index: 999;
  position: fixed;
  // overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
}

.el-tabs .el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
}

.el-tabs .el-tabs__item {
  padding: 0 20px !important;
}
.el-tabs__header {
  margin: 0 !important;
}
.logo {
  position: absolute !important;
  left: 2.6%;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 20px;
  font-weight: 700;
  .imgBox {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}
.nav {
  margin: 0 auto;
  .el-tabs {
    .el-tabs__nav-wrap {
      overflow: visible !important;
      .el-tabs__nav-scroll {
        overflow: visible !important;
      }
    }
  }
  .navItem {
    height: 80px;
    .children {
      display: none;
      min-width: 100%;
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translateX(-50%);
      background: #fff;
      z-index: 999;
      text-align: center;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 0 0 6px 6px;
      div {
        font-size: 14px;
        padding: 0 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: #333;
        &:hover {
          color: #409eff;
        }
      }
      // &:after {
      //   content: "";
      //   width: 0;
      //   height: 0;
      //   border-width: 8px;
      //   border-style: solid;
      //   border-color: transparent transparent #fff transparent;
      //   position: absolute;
      //   top: -16px;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }
    }
    &:hover {
      .children {
        display: block;
      }
    }
  }
}
.phone {
  position: absolute;
  right: 2.6%;
  top: 50%;
  transform: translateY(-50%);
}
.login-bt {
  color: #333;
  font-size: 14px;
  span {
    display: inline-block;
    margin: 0 5px;
    border: 1px solid #409eff;
    border-radius: 6px;
    padding: 4px;
    font-size: 14px;
    &:hover {
      background-color: #409eff;
      cursor: pointer;
      color: #fff;
    }
  }
}
@media (max-width: 1700px) {
  .el-tabs .el-tabs__item {
    padding: 0 15px !important;
  }
}
@media (max-width: 1500px) {
  .el-tabs .el-tabs__item {
    padding: 0 10px !important;
  }
  .phone {
    display: none;
  }
}
@media (max-width: 1200px) {
  .logo {
    font-size: 16px;
  }
}
</style>
